<template>
    <div class="flex justify-betwee font-frank text-center font-500 text-sm  overflow-hidden">
        <div
            v-for="option in options"
            class="cursor-pointer py-4 px-6 tb-option"
            :class="getClassList(option)"
            :key="option.value"
            @click="update(option)"
        >
            {{ option.label }}
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                required: true
            },
            options: {
                type: Array,
                required: true
            },
        },
        emits: ['input'],
        data: () => {
            return {
                activeOptionValue: null
            }
        },
        methods: {
            update(option) {
                this.activeOptionValue = option.value;
                this.$emit('input', this.activeOptionValue);
            },
            getClassList(option) {
                let _classList = [
                    // 'w-1/' + this.options.length
                ];
                if(option.value === this.activeOptionValue) {
                    _classList.push('selected');
                }
                return _classList.join(' ');
            }
        },
        watch: {
            value: {
                handler: function () {
                    this.activeOptionValue = this.value
                },
                immediate: true
            }
        }
    }
</script>
<style>
    .tb-option{
        background: var(--highlightColor200);
        border: 1px solid var(--highlightColor200);
        color: var(--highlightColor400);
    }
    .tb-option:hover{
        background: var(--highlightColor300);
        border-color: var(--highlightColor300);
        color: var(--highlightColorBase);
    }
    .tb-option.selected{
        @apply text-white;
        border-color: var(--highlightColorBase);
        background-color: var(--highlightColorBase);
    }
</style>
