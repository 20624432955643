<template>
  <div class="w-full flex flex-col flex-1 h-full">
    <loader :loading="loading" :backdrop="true" />
    <!-- Select Partner -->
    <section class="w-full flex justify-between">
      <div class="flex flex-row">
        <div class="flex flex-col">
          <label class="text-base font-bold">select a partner</label>
          <dropdown
            :options="partners"
            :placeholder="'select partner'"
            v-model="selected_partner"
          ></dropdown>
          <div class="text-blue-500 mt-2">
            <button
              class="cursor-pointer border-b border-blue-500 font-bold"
              @click="is_adding_new_partner = true"
            >
              add new partner
              <icon
                name="plus"
                class="h-4 w-4 inline text-blue-500 cursor-pointer"
              />
            </button>
          </div>
        </div>
        <div v-if="is_filterin_by_doc" class="flex flex-col mx-4">
          <label class="text-base font-bold">select a partner document</label>
          <dropdown
            :options="partners_documents"
            :placeholder="'select partner document'"
            v-model="selected_document"
          ></dropdown>
        </div>
      </div>
      <div v-if="selected_partner" class="flex">
        <label class="text-base font-bold mr-4 mt-2">filter by document:</label>
        <ToggleButton
          class="h-10 text-center"
          name="filterDocument"
          :options="toggle_options"
          v-model="is_filterin_by_doc"
        />
      </div>
    </section>
    <!-- Partner List -->
    <section class="w-full" v-if="selected_partner">
        <ListDocument
          :columns="columns"
          :filter_doc="selected_document"
          :key="key"
          @click="handleRowClick"
        ></ListDocument>
    </section>
    <!-- Add new partner -->
    <section class="w-full" v-if="is_adding_new_partner">
      <div class="z-50 bottom-0 left-0 fixed w-full bg-white pb-6">
        <h3 class="bg-gray-300 py-2 px-8">add new partner</h3>
        <div class="p-8 w-1/4">
          <div class="w-full flex flex-col justify-start">
            <text-input
              id="partner_name"
              placeholder="name"
              v-model="partner_name"
              label="partner name"
            />
            <span v-if="hasError('partner_name')" class="form-error">
              {{ showError("partner_name") }}
            </span>
          </div>
        </div>
        <div class="flex justify-between p2-8 px-8">
          <div class="">
            <button @click="(partner_name = '', is_adding_new_partner = false)">cancel</button>
          </div>
          <button @click="savePartner" class="btn-primary btn-solid">
            save
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Loader from "@/components/ui/Loader";
  import Dropdown from "@/components/ui/Dropdown";
  import Icon from "@/components/ui/Icon";
  import ToggleButton from "@/components/ui/ToggleButtons.vue";
  import ListDocument from "@/views/lease/PartnerDocuments/ListDocuments.vue";
  import TextInput from '@/components/ui/TextInput';
  
  import { mapActions, mapGetters } from 'vuex';
  import EventBus from '@/utils/EventBus.js';

  export default {
    name: "ManagePartnerDocuments",
    mixins: [NotifyMixin, ModalNavigation],
    components: {
      ToggleButton,
      Dropdown,
      Loader,
      Icon,
      ListDocument,
      TextInput
    },
    data() {
      return {
        loading: false,
        filterValues: {},
        toggle_options: [
          { label: "yes", value: true },
          { label: "no", value: false },
        ],
        is_filterin_by_doc: false,
        partners: [],
        selected_partner: null,
        selected_document: null,
        partner_id: '',
        partners_documents: [],
        partner_fields: [],
        selected_document_id: null,
        quext_fields: [],
        translation_to_delete: null,
        is_adding_partner_field: false,
        current_row: null,
        is_row_editing: false,
        data_type: null,
        index: -1,
        key: -1,
        field: {
          name: '',
          description: '',
          data_type: null
        },
        is_adding_new_partner: false,
        partner_name: '',
        is_adding_document: false,
      };
    },
    validations: {
      partner_name: "required",
      // 'field.name': "required | is_empty",
      // 'field.description': "required | is_empty",
      // 'field.data_type': "required | is_empty",
    },
    computed: {
      data_types() {
        return [{
          key: 'string',
          value: 'string'
        },
        {
          key: 'numeric',
          value: 'numeric'
        },
        {
          key: 'date',
          value: 'date'
        },
        {
          key: 'boolean',
          value: 'boolean'
        }]
      },
      ...mapGetters({
        getPartner: 'partner_documents/getPartner'
      })
    },
    emits: ['add-document'],
    methods: {
      ...mapActions({
        addAction: 'actions/addAction',
        removeAction: 'actions/removeAction',
        setPartnerField: 'partner_documents/setPartnerField',
        setPartner: 'partner_documents/setPartner'
      }),
      handleRowClick(row) {
        this.setPartnerField(row.data);
        this.$router.push({name: 'partner-field.edit'});
      },
      apiFilters() {
        const filters = {};

        if (this.filterValues.searchQuery) {
          filters.q = this.filterValues.searchQuery;
        }

        return filters;
      },
      fetchPartners() {
        this.loading = true;
        this.$leasingDataProvider.getList('partner')
        .then((res) => {
          this.partners = res.data.map(partner => {
            return {
              partner_id: partner.partner_id,
              key: partner.partner_id,
              value: partner.partner_name.trim().toLowerCase()
            }
          });
          this.selected_partner = this.getPartner;
        })
        .catch(() => this.notifyError('Something went wrong, please try again later. 1'))
        .finally(() => this.loading = false)
      },
      fetchPartnerDocuments(partner_id) {
        this.loading = true;
        this.$leasingDataProvider.getList('partner_document', { partner_id })
        .then((res) => {
          if (res.status === 200) {
            this.partners_documents = res.data.map(doc => {
              return {
                ...doc,
                key: doc.partner_document_id,
                value: doc.document_name.trim().toLowerCase()
              };
            });
            this.selected_document = this.partners_documents[0].partner_document_id;
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.notifyError('Something went wrong, please try again later. 2')
        })
        .finally(() => this.loading = false)
      },
      selectDocument(document) {
        this.selected_document = {...document};
      },
      handleActions() {
        if (this.selected_partner) {
          this.addAction({
            routeName: this.$route.name,
            item: {
              title: 'add new document',
              routeName: 'add-partner-document.create',
              params: { partner: this.selected_partner }
            },
          });
          this.addAction({
            routeName: this.$route.name,
            item: {
              title: 'add new field',
              routeName: 'add-partner-field.create',
            },
          });
          this.addAction({
            routeName: this.$route.name,
            item: {
              id: 'delete-partner',
              title: 'delete partner',
              showConfirmation: true,
              confirmationType: 'warning',
              confirmationMessage: `are you sure you want to delete ${this.selected_partner}`,
              confirmBtnText: 'yes, delete',
              cancelBtnText: 'no, cancel',
            },
          });
          this.addAction({
            routeName: this.$route.name,
            item: {
              id: 'manage-quext-fields',
              title: 'manage quext fields',
              routeName: 'add-quext-field.manage',
            },
          });
        }
      },
      deletePartner() {
        this.$leasingDataProvider.delete('partner', { partner_id: this.getPartner})
        .then(() => {
          this.selected_partner = null;
          this.fetchPartners();
          this.notifySuccess("the translation field was sucessfully deleted.");
        })
        .catch(() => this.notifyError('Something went wrong, please try again later. 3'))
        .finally(() => this.loading = false);
      },
      cancelPartnerField() {
        this.is_adding_partner_field = false;
        this.field = {
          name: '',
          description: '',
          data_type: null
        };
      },
      savePartnerField() {
        if (this.validator.passes()) {
          this.loading = true;
          this.$leasingDataProvider.create('partner_field', {
            data: {
              partner_id: this.partner_id,
              partner_field_name: this.field.name,
              alt_display_name: this.field.name,
              data_type: this.field.data_type,
              description: this.field.description,
              begin_use_on: null,
              stopped_use_on: null
            }
          })
          .then(() => {
            this.is_adding_partner_field = false;
            this.field = {
              name: '',
              description: '',
              data_type: null
            };
            this.notifySuccess("the partner field was sucessfully created.");
          })
          .catch(() => this.notifyError('Something went wrong, please try again later. 4'))
          .finally(() => this.loading = false);
        }
      },
      savePartner() {
        if (this.validator.passes()) {
          this.loading = true;
          this.$leasingDataProvider.create('partner', {
            data: {
              partner_name: this.partner_name,
            }
          })
          .then(() => {
            this.partner_name = '';
            this.is_adding_new_partner = false;
            this.fetchPartners();
            this.notifySuccess("the new partner was sucessfully created.");
          })
          .catch(() => this.notifyError('Something went wrong, please try again later. 5'))
          .finally(() => this.loading = false);
        }
      }
    },
    watch: {
      is_filterin_by_doc: function() {
        if (this.is_filterin_by_doc && !this.partners_documents.length) this.fetchPartnerDocuments(this.selected_partner);
      },
      selected_document: function() {
        if (this.selected_document) this.key = this.key + 1;
        else this.key = -1;
      },
      selected_partner: function() {
        if (this.selected_partner) {
          this.key = this.key + 1;
          this.setPartner(this.selected_partner);
          this.handleActions();
        } else {
          this.key = -1;
          this.setPartner(null);
        }
      }
    },
    created() {
      this.initValidator();
      this.fetchPartners();

      EventBus.on('confirm-delete-partner', () => {
        this.deletePartner();
      });
    },
  };
</script>

<style scoped>
.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.isPartnerDocActive {
  @apply bg-blue-100 text-blue-500 px-2;
}
button {
  outline: none;
  box-shadow: none;
}
</style>
