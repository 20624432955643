<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-flagiconlist"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-flagiconlist">
    <div v-if="isFlagged">
      <div>
        <icon name="flag" class="h-5 w-5 inline mr-4 text-blue-500"></icon>
      </div>
    </div>
    <div v-else>n/a</div>
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';
  import Icon from "@/components/ui/Icon";

  export default {
    name: 'FlagIconList',
    mixins: [VuetableFieldMixin],
    components: {
      Icon
    },
    computed: {
      isFlagged() {
        return this.rowData?.remove_char_regx ? true : false;
      },
    },
  };
</script>
